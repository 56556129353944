import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { apiClient } from "../../api/apiClient";
import { useQuery } from "@tanstack/react-query";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  type EvidenceRequestQueryResult,
  type EvidenceRequest,
  EvidenceRequestTableUpdateArg,
  EvidenceRequestApiResponse,
} from "../../types/evidencerequests";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { User } from "../../types/users";

export function useCreateEvidenceRequest(
  table?: EvidenceRequestTableUpdateArg
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (evidencerequest: EvidenceRequest) => {
      const updatedEvidenceRequest = {
        ...evidencerequest,
        controls: evidencerequest.controls?.map((control) => control.id),
        engagements: evidencerequest.engagements?.map(
          (engagement) => engagement.id
        ),
        users: evidencerequest.users?.map((user) => user.id),
        verifiers: evidencerequest.verifiers?.map((verifier) => verifier.id),
        organization: evidencerequest.organization?.id,
      };

      try {
        const response = await apiClient.post(
          "/api/evidencerequests/",
          updatedEvidenceRequest
        );

        if (table) {
          table.table.setCreatingRow(null);
        }
        toast.success(
          `Evidence Request ${evidencerequest.title} created successfully`
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
          throw error;
        } else {
          toast.error(`Error creating Evidence Request: ${error}`);
        }
      }
    },
    onSuccess: (newEvidenceRequest: EvidenceRequest) => {
      queryClient.setQueryData<EvidenceRequest[]>(
        ["evidencerequests"],
        (prevEvidenceRequests = []) => [
          ...prevEvidenceRequests,
          newEvidenceRequest,
        ]
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["evidencerequests"] }),
  });
}

export function useDeleteEvidenceRequest() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (evidencerequestId: string) => {
      try {
        await apiClient.delete(`/api/evidencerequests/${evidencerequestId}`);
        toast.success("Evidence Request successfully deleted");
      } catch (error) {
        toast.error("Failed to delete the Evidence Request");
      }
    },
    onMutate: (evidencerequestId: string) => {
      queryClient.setQueryData(
        ["evidencerequests"],
        (prevEvidenceRequests: any) =>
          prevEvidenceRequests?.filter(
            (evidencerequest: EvidenceRequest) =>
              evidencerequest.id !== evidencerequestId
          )
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["evidencerequests"] }),
  });
}

export function useSearchEvidenceRequests({
  q,
  organization,
}: {
  q: string;
  organization?: string;
}) {
  return useQuery<EvidenceRequestQueryResult[]>({
    queryKey: ["evidencerequests", q, organization],
    queryFn: async () => {
      try {
        let url = `/api/evidencerequests/?search=${q}`;
        if (organization) {
          url += `&organization=${encodeURIComponent(organization)}`;
        }

        const response = await apiClient.get(url);

        return [
          {
            evidencerequests: response.data.results as EvidenceRequest[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ evidencerequests: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export const useSearchEvidenceRequestsForFiles = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [searchResults, setSearchResults] = useState<EvidenceRequest[]>([]);

  const searchEvidenceRequests = useCallback(
    async (
      searchQuery?: string,
      organization?: string,
      engagements?: string[]
    ) => {
      setLoading(true);
      setError(null);
      try {
        let url = `/api/evidencerequests-file/?`;
        if (searchQuery) {
          url += `&search=${searchQuery}`;
        }
        if (organization) {
          url += `&organization=${organization}`;
        }
        if (engagements && engagements.length > 0) {
          url += `&engagements=${encodeURIComponent(
            JSON.stringify(engagements)
          )}`;
        }
        const response = await apiClient.get<EvidenceRequestApiResponse>(url);
        setSearchResults(response.data.results);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    []
  ); // Memoize the search function

  return {
    searchEvidenceRequests,
    loading,
    error,
    searchResults,
  };
};
export function useGetEvidenceRequests({
  pagination,
  columnFilters,
  sorting,
  globalFilter,
  exportCsv,
  relatedParentField,
  relatedParentId,
  showRelatedOnly,
}: {
  pagination: MRT_PaginationState;
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  globalFilter: string | undefined;
  exportCsv?: boolean;
  relatedParentField?: string | undefined;
  relatedParentId?: string | undefined;
  showRelatedOnly?: boolean;
}) {
  return useQuery<EvidenceRequestQueryResult[]>({
    queryKey: [
      "controls",
      pagination,
      columnFilters,
      sorting,
      globalFilter,
      exportCsv,
    ],
    queryFn: async () => {
      try {
        const url = `/api/evidencerequests`;
        const params = new URLSearchParams({
          page: (pagination.pageIndex + 1).toString(),
          limit: pagination.pageSize.toString(),
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
          globalFilter: globalFilter ?? "",
          relatedParentField: relatedParentField ?? "",
          relatedParentId: relatedParentId ?? "",
          showRelatedOnly: showRelatedOnly ? "true" : "false",
        });

        const response = await apiClient.get(url, { params });

        return [
          {
            evidencerequests: response.data.results as EvidenceRequest[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ evidencerequests: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

interface EvidenceRequestStats {
  status: string;
  count: number;
}

interface EvidenceRequestStatsApiResponse {
  status_counts: Array<EvidenceRequestStats>;
}

export function useGetEvidenceRequestStats({
  relatedParentField,
  relatedParentId,
  showRelatedOnly,
}: {
  relatedParentField?: string | undefined;
  relatedParentId?: string | undefined;
  showRelatedOnly?: boolean;
}) {
  return useQuery<EvidenceRequestStatsApiResponse>({
    queryKey: ["controls"],
    queryFn: async () => {
      try {
        const url = `/api/evidencerequests/stats/`;
        const params = new URLSearchParams({
          relatedParentField: relatedParentField ?? "",
          relatedParentId: relatedParentId ?? "",
          showRelatedOnly: showRelatedOnly ? "true" : "false",
        });

        const response = await apiClient.get(url, { params });

        return response.data;
      } catch (error) {
        //
        return [{ stats: [] }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useGetEvidenceRequest({ id }: { id: string }) {
  return useQuery<EvidenceRequestQueryResult>({
    queryKey: ["evidencerequests", id],
    queryFn: async () => {
      if (!id || id === "") {
        return { evidencerequests: [], rowCount: 0 };
      }
      try {
        const url = `/api/evidencerequests?id=${id}`;
        const response = await apiClient.get(url);

        return {
          evidencerequests: response.data.results as EvidenceRequest[],
          rowCount: response.data.count,
        };
      } catch (error) {
        //
        return { evidencerequests: [], rowCount: 0 };
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useUpdateEvidenceRequest(
  table?: EvidenceRequestTableUpdateArg
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (evidencerequest: EvidenceRequest) => {
      const updatedEvidenceRequest = {
        ...evidencerequest,
        controls: evidencerequest.controls?.map((control) => control.id),
        engagements: evidencerequest.engagements?.map(
          (engagement) => engagement.id
        ),
        users: evidencerequest.users?.map((user) => user.id),
        verifiers: evidencerequest.verifiers?.map((verifier) => verifier.id),
        organization: evidencerequest.organization?.id,
      };

      try {
        await apiClient.patch(
          `/api/evidencerequests/${evidencerequest.id}/`,
          updatedEvidenceRequest
        );

        if (table) {
          table.table.setEditingRow(null);
        }
        toast.success(
          `Evidence Request ${evidencerequest.title} updated successfully`
        );
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
          throw error;
        } else {
          toast.error(`Error updating Evidence Request ${error}`);
        }
      }
    },

    onMutate: (newEvidenceRequestInfo: EvidenceRequest) => {
      queryClient.setQueryData(
        ["evidencerequests"],
        (prevEvidenceRequests: any) =>
          prevEvidenceRequests?.map((prevEvidenceRequest: EvidenceRequest) =>
            prevEvidenceRequest.id === newEvidenceRequestInfo.id
              ? newEvidenceRequestInfo
              : prevEvidenceRequest
          )
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["evidencerequests"] }),
  });
}

export const useExportEvidenceRequests = (columnFilters: any, sorting: any) => {
  const exportEvidenceRequests = async () => {
    try {
      const response = await apiClient.get("/api/evidencerequests/export/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      const timestamp = now.getTime();
      const filename = `EvidenceRequests-${dateString}-${timestamp}.csv`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();

      toast.success(`${filename} downloaded successfully`);
    } catch (error) {
      toast.error(`Failed to download file : ${error}`);
    }
  };

  return exportEvidenceRequests;
};

export const useDeleteEvidenceRequests = (
  columnFilters: any,
  sorting: any,
  rowCount: number
) => {
  const deleteEvidenceRequests = async () => {
    try {
      await apiClient.get("/api/evidencerequests/delete/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
      });

      toast.success(`Deleted ${rowCount} rows successfully`);
    } catch (error) {
      toast.error("Failed to delete rows");
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return deleteEvidenceRequests;
};

export const useSubmitForReviewEvidenceRequest = (id: string) => {
  const submitEvidenceRequestForReview = async (note: string) => {
    const data = { note };
    try {
      await apiClient.post(
        `/api/evidencerequests/${id}/submit-for-review/`,
        data
      );
      toast.success("Evidence Request submitted for review.");
      return true;
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data);
        return false;
      } else {
        toast.error("Something went wrong during the submission.");
        return null;
      }
    }
  };

  return submitEvidenceRequestForReview;
};

export const useStartReviewEvidenceRequest = (id: string) => {
  const startEvidenceRequestReview = async () => {
    try {
      await apiClient.post(`/api/evidencerequests/${id}/start-review/`);
      toast.success("Evidence Request set to In review.");
      return true;
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data);
        return false;
      } else {
        toast.error("Something went wrong during the submission.");
        return null;
      }
    }
  };

  return startEvidenceRequestReview;
};

export const useAcceptEvidenceRequest = (id: string) => {
  const acceptEvidenceRequest = async (note: string) => {
    const data = { note };
    try {
      await apiClient.post(`/api/evidencerequests/${id}/accept/`, data);
      toast.success("Evidence Request has been accepted.");
      return true;
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data);
        return false;
      } else {
        toast.error("Something went wrong during the submission.");
        return null;
      }
    }
  };

  return acceptEvidenceRequest;
};

export const useRequestFollowUpEvidenceRequest = (id: string) => {
  const requestFollowUpEvidenceRequest = async (note: string) => {
    try {
      const data = { note };
      await apiClient.post(
        `/api/evidencerequests/${id}/follow-up-required/`,
        data
      );
      toast.success("Follow up has been requested on the Evidence Request.");
      return true;
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data);
        return false;
      } else {
        toast.error("Something went wrong during the submission.");
        return null;
      }
    }
  };

  return requestFollowUpEvidenceRequest;
};

interface Sender {
  id: string;
  name: string;
}

interface Message {
  id: string;
  senderId: string;
  sender: User;
  content: string;
  timestamp: string;
  users_mentioned: User[];
  hidden: boolean;
}

interface Conversation {
  id: string;
  messages: Message[];
}

export const useEvidenceRequestChat = (conversationId: string) => {
  const queryClient = useQueryClient();

  const { data: conversation, isLoading } = useQuery<Conversation, Error>({
    queryKey: ["conversations", conversationId],
    queryFn: async () => {
      try {
        const response = await apiClient.get(
          `/api/conversations/${conversationId}/fetch/`
        );
        return response.data;
      } catch (error) {
        //
      }
    },
    enabled: !!conversationId,
  });

  const sendMessageMutation = useMutation({
    mutationFn: async ({
      content,
      usersMentioned,
      filesMentioned,
    }: {
      content: string;
      usersMentioned: string[];
      filesMentioned: string[];
    }) => {
      if (!conversation) throw new Error("Conversation not loaded");
      await apiClient.post(`/api/conversations/${conversation.id}/messages/`, {
        content: content,
        usersMentioned: usersMentioned,
        filesMentioned: filesMentioned,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["conversations", conversationId],
      });
    },
    onError: (error: any) => {
      toast.error("Failed to send message");
    },
  });

  const sendMessage = (messageData: {
    content: string;
    usersMentioned: string[];
    filesMentioned: string[];
  }) => {
    sendMessageMutation.mutate(messageData);
  };

  return { conversation, isLoading, sendMessage };
};

export const useGetChatUsers = (conversationId: string) => {
  const { data: users, isLoading } = useQuery<User[], Error>({
    queryKey: ["chat_users", conversationId],
    queryFn: async () => {
      try {
        const response = await apiClient.get(
          `/api/conversations/${conversationId}/fetch-users/`
        );
        return response.data || [];
      } catch (error) {
        //
      }
    },
    enabled: !!conversationId,
  });

  return { users, isLoading };
};

export const useToggleMessageHidden = (conversationId: string) => {
  const queryClient = useQueryClient();

  const toggleHiddenMutation = useMutation({
    mutationFn: async (messageId: string) => {
      await apiClient.post(`/api/conversations/toggle-hidden/`, {
        message_id: messageId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["conversations", conversationId],
      });
      toast.success("Message visibility toggled");
    },
    onError: (error: any) => {
      toast.error("Failed to toggle message visibility");
    },
  });

  const toggleHidden = (messageId: string) =>
    toggleHiddenMutation.mutate(messageId);

  return { toggleHidden };
};
