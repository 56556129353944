import React, { useEffect, useState } from "react";
import {
  Card,
  TextField,
  Avatar,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete } from "@mui/material";
import { EvidenceRequest } from "../../../types/evidencerequests";
import { useSearchUsers } from "../../../hooks/users";
import { User } from "../../../types/users";
import { Link } from "react-router-dom";
import { UserAvatar } from "./avatar";

interface UserSelectorProps {
  editedEvidenceRequest: EvidenceRequest;
  setEditedEvidenceRequest: React.Dispatch<
    React.SetStateAction<EvidenceRequest | undefined>
  >;
  editMode?: boolean;
}

const UserSelector: React.FC<UserSelectorProps> = ({
  editedEvidenceRequest,
  setEditedEvidenceRequest,
  editMode = true,
}) => {
  const [inputValue, setInputValue] = useState("");

  const { data: userQueryResult, refetch } = useSearchUsers({
    q: inputValue,
    intent: "users",
    organization:
      editedEvidenceRequest.engagements?.[0]?.organization?.id || "",
  });
  const users = userQueryResult?.[0]?.users || [];

  useEffect(() => {
    if (inputValue.length >= 3) {
      refetch();
    }
  }, [inputValue, refetch]);

  const handleRemoveUser = (userId: string) => {
    const filteredUsers = editedEvidenceRequest.users.filter(
      (user) => user.id !== userId
    );
    setEditedEvidenceRequest({
      ...editedEvidenceRequest,
      users: filteredUsers,
    });
  };

  const stringToColor = (string: string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 90%, 40%)`;
    return color;
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          padding: 2,
          boxSizing: "border-box",
          height: editMode ? "24vh" : "18vh",
        }}
      >
        <Typography sx={{ m: 2 }} variant="h5">
          Users
        </Typography>
        {editMode && (
          <Autocomplete
            multiple
            id="user-search"
            options={users}
            renderTags={() => {
              return null;
            }}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name} - ${option.email}`
            }
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={editedEvidenceRequest.users}
            onChange={(event, newValue: User[]) => {
              setEditedEvidenceRequest({
                ...editedEvidenceRequest,
                users: newValue,
              });
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search and select users"
                placeholder="Type to search..."
              />
            )}
          />
        )}
        <Box sx={{ marginTop: 2, display: "flex", flexWrap: "wrap", gap: 2 }}>
          {editedEvidenceRequest.users.map((user: User) => (
            <Box
              key={user.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <UserAvatar user={user} />

              {editMode && (
                <IconButton
                  onClick={() => handleRemoveUser(user.id)}
                  size="small"
                >
                  <CloseIcon sx={{ color: "black" }} />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
      </Card>
    </>
  );
};

export default UserSelector;
