import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled } from "@mui/system";
import { User } from "@sentry/react";
import { EvidenceFile } from "../../types/evidencefiles";
import { EvidenceRequest } from "../../types/evidencerequests";
import { EvidenceFileButton } from "../../pages/evidencerequest/evidenceFileButton";
import {
  useEvidenceRequestChat,
  useGetChatUsers,
  useToggleMessageHidden,
} from "../../hooks/evidencerequests";
import { useGetEvidenceFiles } from "../../hooks/evidencefiles";
import { getUserInfo } from "../../helpers/user";

interface Mention {
  id: string;
  displayName: string;
  startIndex: number;
  endIndex: number;
  pageNumber?: number;
  type: "user" | "file";
}
interface MentionItem {
  id: string;
  displayName?: string;
  name?: string;
  original_name?: string;
  type: "user" | "file";
  pageNumber?: number;
}

interface MessageState {
  displayText: string;
  mentions: Mention[];
  cursorPosition: number;
}

const TAG_START = "<user-start>";
const TAG_END = "<user-end>";

const DOC_TAG_START = "<doc-start>";
const DOC_TAG_END = "<doc-end>";

const TAG_MARKERS = {
  user: {
    trigger: "@",
    startTag: TAG_START,
    endTag: TAG_END,
  },
  file: {
    trigger: "#",
    startTag: DOC_TAG_START,
    endTag: DOC_TAG_END,
  },
};

const MentionTag = styled("span")({
  fontWeight: "bold",
  borderRadius: "4px",
  padding: "0 2px",
});

const MentionManager = {
  findActiveTrigger(text: string, cursorPosition: number) {
    const textBeforeCursor = text.slice(0, cursorPosition);
    const lastAtIndex = textBeforeCursor.lastIndexOf("@");
    const lastHashIndex = textBeforeCursor.lastIndexOf("#");

    if (lastAtIndex === -1 && lastHashIndex === -1) return null;

    const lastIndex = Math.max(lastAtIndex, lastHashIndex);
    const textAfterTrigger = textBeforeCursor.slice(lastIndex + 1);

    if (!/\s/.test(textAfterTrigger)) {
      return {
        type: textBeforeCursor[lastIndex] === "@" ? "user" : "file",
        position: lastIndex,
        searchText: textAfterTrigger.toLowerCase(),
      };
    }
    return null;
  },

  insertMention(
    currentState: MessageState,
    mention: {
      id: string;
      displayName: string;
      type: "user" | "file";
      pageNumber?: number;
    }
  ): MessageState {
    const trigger = TAG_MARKERS[mention.type].trigger;
    const activeTrigger = MentionManager.findActiveTrigger(
      currentState.displayText,
      currentState.cursorPosition
    );

    if (!activeTrigger) return currentState;

    const beforeTrigger = currentState.displayText.slice(
      0,
      activeTrigger.position
    );
    const afterCursor = currentState.displayText.slice(
      currentState.cursorPosition
    );

    const displayText = mention.pageNumber
      ? `${mention.displayName}$${mention.pageNumber}`
      : mention.displayName;

    const newDisplayText = `${beforeTrigger}${trigger}${displayText} ${afterCursor}`;

    const newMention: Mention = {
      id: mention.id,
      displayName: mention.displayName,
      startIndex: activeTrigger.position,
      endIndex: activeTrigger.position + displayText.length + 1,
      type: mention.type,
      pageNumber: mention.pageNumber,
    };

    return {
      displayText: newDisplayText,
      mentions: [...currentState.mentions, newMention],
      cursorPosition: activeTrigger.position + displayText.length + 2,
    };
  },
  generatePayloadMessage(state: MessageState): string {
    let payloadMessage = state.displayText;
    const sortedMentions = [...state.mentions].sort(
      (a, b) => b.startIndex - a.startIndex
    );

    for (const mention of sortedMentions) {
      const { startTag, endTag } = TAG_MARKERS[mention.type];

      const displayText = payloadMessage.slice(
        mention.startIndex + 1,
        mention.endIndex
      );

      const mentionText = `${startTag}${mention.id}:${displayText}${endTag}`;

      payloadMessage =
        payloadMessage.slice(0, mention.startIndex) +
        mentionText +
        payloadMessage.slice(mention.endIndex + 1);
    }

    return payloadMessage;
  },
};

interface ParsedContent {
  type: "text" | "userMention" | "fileMention";
  content: string;
  metadata?: {
    id?: string;
    email?: string;
    originalName?: string;
    pageNumber?: number;
  };
}

const parseMessageContent = (
  content: string,
  evidenceRequest: EvidenceRequest,
  users: User[],
  files: EvidenceFile[]
) => {
  const TAG_START = "<user-start>";
  const TAG_END = "<user-end>";
  const DOC_TAG_START = "<doc-start>";
  const DOC_TAG_END = "<doc-end>";

  const parseUserTag = (tag: string): ParsedContent => {
    const tagContent = tag.slice(TAG_START.length, -TAG_END.length);
    const [userId, ...rest] = tagContent.split(":");
    const user = users.find((u) => u.id === userId);
    const displayText = rest.join(":") || (user ? user.name : userId);

    return {
      type: "userMention",
      content: displayText,
      metadata: {
        id: userId,
        email: user?.email,
      },
    };
  };

  const parseFileTag = (tag: string): ParsedContent => {
    const tagContent = tag.slice(DOC_TAG_START.length, -DOC_TAG_END.length);
    const [fileId, ...rest] = tagContent.split(":");
    const displayText = rest.join(":") || "";

    let pageNumber: number | undefined;
    let fileName = displayText;

    const dollarIndex = displayText.lastIndexOf("$");
    if (dollarIndex !== -1) {
      const potentialPage = displayText.slice(dollarIndex + 1);
      const page = parseInt(potentialPage);
      if (!isNaN(page)) {
        pageNumber = page;
        fileName = displayText.slice(0, dollarIndex);
      }
    }

    const file = files.find((f) => f.id === fileId);
    const finalDisplayText = fileName || (file ? file.original_name : fileId);

    return {
      type: "fileMention",
      content: finalDisplayText,
      metadata: {
        id: fileId,
        originalName: file?.original_name,
        pageNumber,
      },
    };
  };

  const parts: ParsedContent[] = [];
  let currentIndex = 0;

  const regex = new RegExp(
    `(${TAG_START}.*?${TAG_END}|${DOC_TAG_START}.*?${DOC_TAG_END}|\n)`,
    "g"
  );

  let match: RegExpExecArray | null;
  while ((match = regex.exec(content)) !== null) {
    if (match.index > currentIndex) {
      parts.push({
        type: "text",
        content: content.slice(currentIndex, match.index),
      });
    }

    if (match[0] === "\n") {
      parts.push({
        type: "text",
        content: "\n",
      });
    } else if (match[0].startsWith(TAG_START)) {
      parts.push(parseUserTag(match[0]));
    } else if (match[0].startsWith(DOC_TAG_START)) {
      parts.push(parseFileTag(match[0]));
    }

    currentIndex = match.index + match[0].length;
  }

  if (currentIndex < content.length) {
    parts.push({
      type: "text",
      content: content.slice(currentIndex),
    });
  }

  return parts.map((part, index) => {
    if (part.type === "text") {
      if (part.content === "\n") {
        return <br key={index} />;
      }
      return <span key={index}>{part.content}</span>;
    }

    if (part.type === "userMention") {
      return (
        <MentionTag
          key={index}
          title={part.metadata?.email || ""}
          sx={{ color: "#2196f3" }}
        >
          @{part.content}
        </MentionTag>
      );
    }

    if (part.type === "fileMention") {
      const file = files.find((f) => f.id === part.metadata?.id);
      return (
        <MentionTag
          key={index}
          sx={{ color: "#4caf50" }}
          title={`${part.metadata?.originalName || ""}${
            part.metadata?.pageNumber
              ? ` (Page ${part.metadata.pageNumber})`
              : ""
          }`}
        >
          #{part.content}
          {part.metadata?.pageNumber && (
            <span className="text-sm text-gray-600">
              :p{part.metadata.pageNumber}
            </span>
          )}
          {file && (
            <EvidenceFileButton
              file={file}
              evidenceRequestId={evidenceRequest.id}
              iconVersion={true}
              smallIcon={true}
              pageNumber={part.metadata?.pageNumber}
              inChat={true}
            />
          )}
        </MentionTag>
      );
    }

    return null;
  });
};

interface ChatMessageProps {
  message: {
    id: string;
    content: string;
    sender: {
      id: string;
      name: string;
      email: string;
      organization: string;
    };
    hidden: boolean;
    users_mentioned: User[];
  };
  userInfo: {
    id: string;
    role: string;
  };
  formattedDate: string;
  toggleHidden: (id: string) => void;
  users: User[] | undefined | [];
  files: EvidenceFile[] | undefined | [];
  evidenceRequest: EvidenceRequest;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  userInfo,
  formattedDate,
  toggleHidden,
  users,
  files,
  evidenceRequest,
}) => {
  return (
    <Box
      sx={{
        maxWidth: "80%",
        padding: "8px 12px",
        backgroundColor:
          message.sender.id === userInfo.id ? "#e0f7fa" : "#f1f8e9",
        borderRadius: "10px",
      }}
    >
      <ListItemText
        title={message.sender.email}
        primary={
          <>
            {message.sender.id === userInfo.id
              ? "You"
              : `${message.sender.name} (${message.sender.organization})`}
            :{" "}
            {parseMessageContent(
              message.content,
              evidenceRequest,
              users || [],
              files || []
            )}
          </>
        }
        primaryTypographyProps={{
          style: { wordWrap: "break-word" },
          component: "div",
        }}
        secondary={`Sent at ${formattedDate}`}
      />
      {message.sender.organization === "My Company" &&
        userInfo.role === "admin" && (
          <>
            {message.hidden ? (
              <VisibilityOff
                color="action"
                onClick={() => toggleHidden(message.id)}
              />
            ) : (
              <Visibility
                color="action"
                onClick={() => toggleHidden(message.id)}
              />
            )}
          </>
        )}
    </Box>
  );
};

const EvidenceRequestChatInterface: React.FC<{
  conversationId: string;
  refetch: CallableFunction;
  evidenceRequest: EvidenceRequest;
}> = ({ conversationId, refetch, evidenceRequest }) => {
  const { conversation, isLoading, sendMessage } =
    useEvidenceRequestChat(conversationId);
  const [messageState, setMessageState] = useState<MessageState>({
    displayText: "",
    mentions: [],
    cursorPosition: 0,
  });

  const [selectedFile, setSelectedFile] = useState<{
    file: EvidenceFile;
    showPageInput: boolean;
  } | null>(null);
  const [showSuggestions, setShowSuggestions] = useState<{
    type: "user" | "file" | string | null;
    items: any[];
  }>({ type: null, items: [] });

  const inputRef = useRef<HTMLInputElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const { users } = useGetChatUsers(conversationId);
  const { data: files } = useGetEvidenceFiles(evidenceRequest.id);
  const userInfo = getUserInfo();
  const { toggleHidden } = useToggleMessageHidden(conversationId);

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = {
      ...messageState,
      displayText: event.target.value,
      cursorPosition: event.target.selectionStart || 0,
    };
    setMessageState(newState);
    updateSuggestions(newState);
  };

  const scrollToBottom = () => {
    const scrollHeight = messagesContainerRef.current?.scrollHeight;
    const height = messagesContainerRef.current?.clientHeight;
    const maxScrollTop = scrollHeight! - height!;
    messagesContainerRef.current?.scrollTo({
      top: maxScrollTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation?.messages]);

  const updateSuggestions = (state: MessageState) => {
    const activeTrigger = MentionManager.findActiveTrigger(
      state.displayText,
      state.cursorPosition
    );

    if (!activeTrigger) {
      setShowSuggestions({ type: null, items: [] });
      return;
    }

    const items =
      activeTrigger.type === "user"
        ? users?.filter((user: User) =>
            user.name.toLowerCase().startsWith(activeTrigger.searchText)
          ) || []
        : files?.filter((file: EvidenceFile) =>
            file.original_name
              .toLowerCase()
              .startsWith(activeTrigger.searchText)
          ) || [];

    const uniqueItems = items.filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id)
    );

    setShowSuggestions({
      type: activeTrigger.type,
      items: uniqueItems,
    });
  };

  const handleFileSelect = (file: EvidenceFile, pageNumber?: number) => {
    handleMentionSelect({
      id: file.id,
      original_name: file.original_name,
      type: "file",
      pageNumber,
    });
  };

  const handleUserSelect = (user: User) => {
    handleMentionSelect({
      id: (user.id as string) || "",
      name: user.name,
      type: "user",
    });
  };

  const handleMentionSelect = (item: MentionItem) => {
    const newState = MentionManager.insertMention(messageState, {
      id: item.id,
      displayName: item.name || item.original_name || "",
      type: item.type,
      pageNumber: item.pageNumber || undefined,
    });

    setMessageState(newState);
    setShowSuggestions({ type: null, items: [] });

    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current?.setSelectionRange(
          newState.cursorPosition,
          newState.cursorPosition
        );
      }, 0);
    }
  };

  const handleSendMessage = (event: React.FormEvent) => {
    event.preventDefault();
    const payloadMessage = MentionManager.generatePayloadMessage(messageState);

    const usersMentioned = messageState.mentions
      .filter((m) => m.type === "user")
      .map((m) => m.id);

    const filesMentioned = messageState.mentions
      .filter((m) => m.type === "file")
      .map((m) => m.id);

    sendMessage({
      content: payloadMessage,
      usersMentioned,
      filesMentioned,
    });

    setMessageState({
      displayText: "",
      mentions: [],
      cursorPosition: 0,
    });
  };

  return (
    <Box sx={{ maxWidth: "600px", margin: "0 auto" }}>
      <Box
        ref={messagesContainerRef}
        sx={{ maxHeight: "55vh", overflow: "auto", pb: 4 }}
      >
        <List>
          {conversation?.messages?.map((message) => (
            <ListItem
              key={message.id}
              sx={{
                display: "flex",
                justifyContent:
                  message.sender.id === userInfo.id ? "flex-end" : "flex-start",
              }}
            >
              <ChatMessage
                message={message}
                userInfo={userInfo}
                formattedDate={new Intl.DateTimeFormat(undefined, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: true,
                }).format(new Date(message.timestamp))}
                toggleHidden={toggleHidden}
                users={users}
                files={files}
                evidenceRequest={evidenceRequest}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          gap: 1,
          mt: 2,
        }}
        onSubmit={handleSendMessage}
      >
        {showSuggestions.type && (
          <Paper
            elevation={3}
            sx={{
              position: "absolute",
              bottom: "100%",
              left: 0,
              right: 0,
              maxHeight: 200,
              overflow: "auto",
            }}
          >
            <List>
              {showSuggestions.items.map((item) => (
                <ListItem
                  key={item.id}
                  onClick={() => {
                    if (showSuggestions.type === "user") {
                      handleUserSelect(item);
                    } else {
                      // const pageNum = prompt("Enter page number (optional):");
                      handleFileSelect(
                        item
                        // pageNum ? parseInt(pageNum) : undefined
                      );
                    }
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <ListItemText
                    primary={
                      showSuggestions.type === "user"
                        ? `${item.name} - ${item.organization}`
                        : item.original_name
                    }
                    secondary={
                      showSuggestions.type === "user"
                        ? item.email
                        : new Date(item.date_created).toLocaleString()
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}

        <TextField
          multiline
          rows={3}
          fullWidth
          inputRef={inputRef}
          label="Type a message..."
          variant="outlined"
          value={messageState.displayText}
          onChange={handleMessageChange}
        />
        <Button type="submit" variant="contained">
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default EvidenceRequestChatInterface;
