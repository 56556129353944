import React, { useCallback, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  Typography,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { InsertDriveFile, Close as CloseIcon } from "@mui/icons-material";
import { useS3Upload } from "../../hooks/evidencefiles";
import { EvidenceFile, UploadingFile } from "../../types/evidencefiles";

interface FileUploaderProps {
  evidenceRequestId: string;
}

const FileUploader = ({ evidenceRequestId }: FileUploaderProps) => {
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFile[]>([]);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const { uploadToS3AndMarkUploaded } = useS3Upload();

  const calculateSHA256 = async (file: File): Promise<string> => {
    const buffer = await file.arrayBuffer();
    const digest = await crypto.subtle.digest("SHA-256", buffer);
    return Array.from(new Uint8Array(digest))
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
  };

  const uploadFile = async (uploadingFile: UploadingFile) => {
    try {
      setUploadingFiles((prev) =>
        prev.map((f) =>
          f.id === uploadingFile.id
            ? { ...f, status: "uploading", progress: 0 }
            : f
        )
      );

      const sha256 = await calculateSHA256(uploadingFile.file);

      const progressInterval = setInterval(() => {
        setUploadingFiles((prev) =>
          prev.map((f) =>
            f.id === uploadingFile.id && f.progress < 90
              ? { ...f, progress: f.progress + 10 }
              : f
          )
        );
      }, 500);

      const uploadResult = await uploadToS3AndMarkUploaded(
        evidenceRequestId,
        uploadingFile.file,
        uploadingFile.original_name,
        sha256,
        uploadingFile.file.type
      );

      clearInterval(progressInterval);

      if (uploadResult.success) {
        setUploadingFiles((prev) =>
          prev.map((f) =>
            f.id === uploadingFile.id
              ? { ...f, status: "success", progress: 100 }
              : f
          )
        );

        if (uploadResult.evidence_file_id) {
          const newFile: EvidenceFile = {
            id: uploadResult.evidence_file_id,
            original_name: uploadingFile.original_name,
            file_url: uploadResult.s3_url || "",
            filetype: uploadingFile.file.type,
            sha256,
            size: uploadingFile.file.size,
            date_created: new Date(),
          };
        }
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      setUploadingFiles((prev) =>
        prev.map((f) =>
          f.id === uploadingFile.id
            ? {
                ...f,
                status: "error",
                error: error instanceof Error ? error.message : "Upload failed",
              }
            : f
        )
      );
    }
  };

  const onFileSelect = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = event.target.files;
      if (!selectedFiles || selectedFiles.length === 0) return;

      const newUploadingFiles: UploadingFile[] = Array.from(selectedFiles).map(
        (file) => ({
          id: crypto.randomUUID(),
          original_name: file.name,
          progress: 0,
          status: "pending",
          file,
        })
      );

      setUploadingFiles((prev) => [...prev, ...newUploadingFiles]);
      setShowUploadProgress(true);

      for (const uploadingFile of newUploadingFiles) {
        await uploadFile(uploadingFile);
      }
    },
    [evidenceRequestId]
  );

  return (
    <>
      <Box sx={{ textAlign: "left" }}>
        <Button sx={{}} variant="contained" component="label">
          Upload Files
          <input type="file" hidden onChange={onFileSelect} multiple />
        </Button>
      </Box>

      <Dialog
        open={showUploadProgress}
        onClose={() => {
          const canClose = uploadingFiles.every(
            (f) => f.status === "success" || f.status === "error"
          );
          if (canClose) {
            setShowUploadProgress(false);
            setUploadingFiles([]);
            window.location.reload();
          }
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          File Upload Progress
          {uploadingFiles.every(
            (f) => f.status === "success" || f.status === "error"
          ) && (
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowUploadProgress(false);
                setUploadingFiles([]);
                window.location.reload();
              }}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          <List>
            {uploadingFiles.map((file) => (
              <ListItem key={file.id}>
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText
                  primary={file.original_name}
                  secondary={
                    file.status === "error" ? (
                      <Typography color="error">{file.error}</Typography>
                    ) : (
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress
                          variant="determinate"
                          value={file.progress}
                          color={
                            file.status === "success" ? "success" : "primary"
                          }
                        />
                        <Typography variant="body2" color="text.secondary">
                          {file.status === "success"
                            ? "Upload complete"
                            : `${Math.round(file.progress)}%`}
                        </Typography>
                      </Box>
                    )
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FileUploader;
