import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Chip, Stack, Alert } from "@mui/material";
import { EvidenceFile } from "../../types/evidencefiles";
import { useGetEvidenceFile } from "../../hooks/evidencefiles";
import { BusinessCenter, HouseRounded, Person } from "@mui/icons-material";
import { GridSaveAltIcon } from "@mui/x-data-grid";
import { ClockIcon } from "@mui/x-date-pickers";
import RenderPDF from "./renderPdf";

const EvidenceFileViewer = ({
  evidenceFile,
  evidenceRequestId,
  pageNumber = 1,
}: {
  evidenceFile: EvidenceFile;
  pageNumber: number;
  evidenceRequestId?: string;
}) => {
  const [fileUrlAnnotated, setFileUrlAnnotated] = useState<string | null>(null);
  const [orgName, setOrgName] = useState<string | null>(null);

  const {
    fetchDownloadUrl: fetchDownloadUrlAnnotated,
    url: urlAnnotated,
    downloadLoading: downloadLoadingAnnotated,
    downloadError: downloadErrorAnnotated,
  } = useGetEvidenceFile("annotated");

  useEffect(() => {
    if (evidenceFile?.engagements?.length) {
      if (evidenceFile.engagements[0].organization)
        setOrgName(evidenceFile.engagements[0].organization.name);
    }
  }, [evidenceFile]);

  useEffect(() => {
    if (evidenceFile?.id) {
      fetchDownloadUrlAnnotated(evidenceFile.id);
    }
  }, [evidenceFile?.id, fetchDownloadUrlAnnotated]);

  useEffect(() => {
    if (urlAnnotated) {
      setFileUrlAnnotated(urlAnnotated);
    }
  }, [urlAnnotated]);

  const { fetchDownloadUrl } = useGetEvidenceFile();

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "completed":
        return "success";
      case "failed":
        return "error";
      case "processing":
        return "warning";
      default:
        return "default";
    }
  };

  const downloadFile = async (evidenceFileId: string, type?: string) => {
    let url = "";
    if (type && type === "annotated") {
      url = urlAnnotated;
    } else {
      url = await fetchDownloadUrl(evidenceFileId);
    }
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderDocumentInfo = () => (
    <Box>
      <Stack direction="row" spacing={1} alignItems="center">
        <Chip
          sx={{ p: 0.5 }}
          label={evidenceFile.conversion_status}
          size="small"
          color={getStatusColor(evidenceFile.conversion_status || "")}
        />
        <Chip
          label="Original"
          sx={{ p: 0.5 }}
          size="small"
          variant="outlined"
          onClick={() => downloadFile(evidenceFile.id)}
          icon={<GridSaveAltIcon />}
        />
        <Chip
          label="Annotated"
          sx={{ p: 0.5 }}
          size="small"
          variant="outlined"
          onClick={() => downloadFile(evidenceFile.id, "annotated")}
          icon={<GridSaveAltIcon />}
        />
        <Chip
          sx={{ p: 0.5 }}
          label={evidenceFile.filetype}
          size="small"
          variant="outlined"
        />
        {evidenceFile.user && evidenceFile.user.name && (
          <Chip
            sx={{ p: 0.5 }}
            title={"Uploaded by " + evidenceFile.user.email}
            label={evidenceFile.user.name}
            size="small"
            icon={<Person />}
          />
        )}
        {orgName && (
          <Chip
            sx={{ p: 0.5 }}
            label={orgName}
            size="small"
            icon={<BusinessCenter />}
          />
        )}
        <Chip
          sx={{ p: 0.5 }}
          label={evidenceFile.date_created.toLocaleString()}
          size="small"
          icon={<ClockIcon />}
        />
      </Stack>
      {evidenceFile.conversion_status === "FAILED" && (
        <Alert severity="error" variant="outlined">
          {evidenceFile.conversion_error}
        </Alert>
      )}
    </Box>
  );

  if (!evidenceFile) {
    return <Typography>No file selected</Typography>;
  }

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {/* {renderDocumentInfo()} */}

      {fileUrlAnnotated && (
        <RenderPDF
          pageNumber={pageNumber}
          renderDocumentInfo={renderDocumentInfo}
          evidenceFile={evidenceFile}
          fileUrlAnnotated={fileUrlAnnotated}
          downloadLoadingAnnotated={downloadLoadingAnnotated}
          downloadErrorAnnotated={downloadErrorAnnotated}
          evidenceRequestId={evidenceRequestId}
        />
      )}
    </Box>
  );
};

export default EvidenceFileViewer;
