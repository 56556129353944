import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { apiClient } from "../../api/apiClient";
import { toast } from "react-toastify";
import {
  EvidenceFile,
  EvidenceFileApiResponse,
} from "../../types/evidencefiles";

interface S3UploadResponse {
  success: boolean;
  s3_url?: string;
  evidence_file_id?: string;
  error?: any;
  message?: string;
}

export const useS3Upload = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const uploadToS3AndMarkUploaded = async (
    evidenceRequestId: string,
    file: File,
    filename: string,
    sha256: string,
    filetype: string
  ): Promise<S3UploadResponse> => {
    setLoading(true);
    setError(null);

    try {
      const { data: s3Data, status } = await apiClient.post(
        "/api/evidencefiles/upload/",
        {
          evidence_request: evidenceRequestId,
          filename,
          sha256,
          filetype,
        }
      );

      if (status === 204) {
        setLoading(false);
        toast.success(`${filename} uploaded successfully`);
        return { success: true, message: "File uploaded successfully." };
      }

      await axios.put(s3Data.s3_url, file, {
        headers: { "Content-Type": "application/octet-stream" },
      });

      await apiClient.post("/api/evidencefiles/mark-uploaded/", {
        evidence_file: s3Data.evidence_file_id,
        evidenceRequestId: evidenceRequestId,
      });
      toast.success(`${filename} uploaded successfully.`);

      setLoading(false);
      return {
        success: true,
        s3_url: s3Data.s3_url,
        evidence_file_id: s3Data.evidence_file_id,
      };
    } catch (error) {
      setError(error);
      setLoading(false);
      return { success: false, error };
    }
  };

  return { uploadToS3AndMarkUploaded, loading, error };
};

// export const useSearchEvidenceFiles = () => {
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<any>(null);
//   const [searchResults, setSearchResults] = useState<EvidenceFile[]>([]);
//   const [totalCount, setTotalCount] = useState<number>(0);
//   const [nextPage, setNextPage] = useState<string | null>(null);
//   const [prevPage, setPrevPage] = useState<string | null>(null);
//   const [currentPage, setCurrentPage] = useState<number>(1);

//   const searchEvidenceFiles = useCallback(
//     async (
//       searchQuery?: string,
//       organization?: string,
//       evidence_request?: string[],
//       engagements?: string[],
//       filetype?: string,
//       uploaded_by?: string,
//       date_from?: string,
//       date_to?: string,
//       processingStatus?: string,
//       page: number = 1
//     ) => {
//       setLoading(true);
//       setError(null);
//       try {
//         let url = `/api/filesearch/?`;

//         if (searchQuery) {
//           url += `&search=${searchQuery}`;
//         }

//         if (processingStatus) {
//           url += `&processingStatus=${processingStatus}`;
//         }

//         if (organization) {
//           url += `&organization=${organization}`;
//         }
//         if (evidence_request && evidence_request.length > 0) {
//           url += `&evidence_request=${encodeURIComponent(
//             JSON.stringify(evidence_request)
//           )}`;
//         }

//         if (engagements && engagements.length > 0) {
//           url += `&engagements=${encodeURIComponent(
//             JSON.stringify(engagements)
//           )}`;
//         }

//         if (filetype) {
//           url += `&filetype=${filetype}`;
//         }

//         if (uploaded_by) {
//           url += `&uploaded_by=${uploaded_by}`;
//         }

//         if (date_from) {
//           url += `&date_from=${date_from}`;
//         }

//         if (date_to) {
//           url += `&date_to=${date_to}`;
//         }

//         if (page) {
//           url += `&page=${page}`;
//         }
//         const response = await apiClient.get<EvidenceFileApiResponse>(url);
//         setSearchResults(response.data.results);
//         setTotalCount(response.data.count);
//         setNextPage(response.data.next);
//         setPrevPage(response.data.previous);
//         setCurrentPage(page);
//       } catch (error) {
//         setError(error);
//       } finally {
//         setLoading(false);
//       }
//     },
//     []
//   );

//   return {
//     searchEvidenceFiles,
//     loading,
//     error,
//     searchResults,
//     totalCount,
//     hasNextPage: !!nextPage,
//     hasPrevPage: !!prevPage,
//     currentPage,
//   };
// };

export const useSearchEvidenceFiles = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [reprocessing, setReprocessing] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [searchResults, setSearchResults] = useState<EvidenceFile[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [prevPage, setPrevPage] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const buildUrlParams = (
    searchQuery?: string,
    organization?: string,
    evidence_request?: string[],
    engagements?: string[],
    filetype?: string,
    uploaded_by?: string,
    date_from?: string,
    date_to?: string,
    processingStatus?: string,
    page: number = 1
  ) => {
    let params = "";
    if (searchQuery) params += `&search=${searchQuery}`;
    if (processingStatus) params += `&processingStatus=${processingStatus}`;
    if (organization) params += `&organization=${organization}`;
    if (evidence_request?.length) {
      params += `&evidence_request=${encodeURIComponent(
        JSON.stringify(evidence_request)
      )}`;
    }
    if (engagements?.length) {
      params += `&engagements=${encodeURIComponent(
        JSON.stringify(engagements)
      )}`;
    }
    if (filetype) params += `&filetype=${filetype}`;
    if (uploaded_by) params += `&uploaded_by=${uploaded_by}`;
    if (date_from) params += `&date_from=${date_from}`;
    if (date_to) params += `&date_to=${date_to}`;
    if (page) params += `&page=${page}`;
    return params;
  };

  const searchEvidenceFiles = useCallback(
    async (
      searchQuery?: string,
      organization?: string,
      evidence_request?: string[],
      engagements?: string[],
      filetype?: string,
      uploaded_by?: string,
      date_from?: string,
      date_to?: string,
      processingStatus?: string,
      page: number = 1
    ) => {
      setLoading(true);
      setError(null);
      try {
        const params = buildUrlParams(
          searchQuery,
          organization,
          evidence_request,
          engagements,
          filetype,
          uploaded_by,
          date_from,
          date_to,
          processingStatus,
          page
        );
        const response = await apiClient.get<EvidenceFileApiResponse>(
          `/api/filesearch/?${params}`
        );
        setSearchResults(response.data.results);
        setTotalCount(response.data.count);
        setNextPage(response.data.next);
        setPrevPage(response.data.previous);
        setCurrentPage(page);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const reProcess = useCallback(
    async (
      searchQuery?: string,
      organization?: string,
      evidence_request?: string[],
      engagements?: string[],
      filetype?: string,
      uploaded_by?: string,
      date_from?: string,
      date_to?: string,
      processingStatus?: string,
      page: number = 1
    ) => {
      setReprocessing(true);
      setError(null);
      try {
        const params = buildUrlParams(
          searchQuery,
          organization,
          evidence_request,
          engagements,
          filetype,
          uploaded_by,
          date_from,
          date_to,
          processingStatus,
          page
        );
        await apiClient.post(`/api/filesearch/reprocess/?${params}`);
        await searchEvidenceFiles(
          searchQuery,
          organization,
          evidence_request,
          engagements,
          filetype,
          uploaded_by,
          date_from,
          date_to,
          processingStatus,
          page
        );
      } catch (error) {
        setError(error);
      } finally {
        setReprocessing(false);
        toast.success("Reprocessing queued successfully");
      }
    },
    [searchEvidenceFiles]
  );

  return {
    searchEvidenceFiles,
    reProcess,
    loading,
    reprocessing,
    error,
    searchResults,
    totalCount,
    hasNextPage: !!nextPage,
    hasPrevPage: !!prevPage,
    currentPage,
  };
};
export const useGetEvidenceFiles = (
  evidenceRequestId: string,
  search: string = "",
  showContent: boolean = false
) => {
  const [items, setItems] = useState<EvidenceFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchData = useCallback(async () => {
    if (!evidenceRequestId) return;
    setLoading(true);
    setError(null);
    try {
      let url = `/api/evidencefiles/?relatedParentId=${evidenceRequestId}&limit=50&search=${search}`;

      if (showContent) {
        url += "&showContent=true";
      }

      const response = await apiClient.get<EvidenceFileApiResponse>(url);
      setItems(response.data.results);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [evidenceRequestId, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data: items,
    loading,
    error,
    refetch: fetchData,
  };
};

export const useProcessEvidenceFiles = () => {
  const [loadingFiles, setLoadingFiles] = useState<Record<string, boolean>>({});
  const [errors, setErrors] = useState<Record<string, any>>({});

  const processFile = useCallback(async (fileId: string) => {
    if (!fileId) return;

    setLoadingFiles((prev) => ({ ...prev, [fileId]: true }));
    setErrors((prev) => ({ ...prev, [fileId]: null }));

    try {
      const url = `/api/evidencefiles/reprocess/?id=${fileId}`;
      await apiClient.post(url);
    } catch (error) {
      setErrors((prev) => ({ ...prev, [fileId]: error }));
    } finally {
      setLoadingFiles((prev) => ({ ...prev, [fileId]: false }));
    }
  }, []);

  return { loadingFiles, errors, processFile };
};

export const useGetEvidenceFile = (download_type?: string) => {
  const [url, setUrl] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(false);

  const fetchDownloadUrl = useCallback(async (evidenceFileId: string) => {
    setDownloadLoading(true);
    try {
      let url = `/api/evidencefiles/download/?id=${evidenceFileId}`;

      if (download_type) {
        url += `&download_type=${download_type}`;
      }

      const response = await apiClient.get(url);
      setUrl(response.data.url);
      setDownloadLoading(false);
      return response.data.url;
    } catch (error) {
      console.error("Error fetching file URL:", error);
      setDownloadError(error as any);
      setDownloadError(false);
      return null;
    }
  }, []);

  return { fetchDownloadUrl, url, downloadLoading, downloadError };
};

export const useExportRelatedEvidenceRequests = (
  parentField: string,
  parentId: any
) => {
  const exportRelatedEvidenceRequests = async () => {
    try {
      const response = await apiClient.get(
        "/api/evidencerequests/export-related/",
        {
          params: {
            parentField: parentField,
            parentId: parentId,
          },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      const timestamp = now.getTime();
      const filename = `Evidence Requests-${parentField}-${dateString}-${timestamp}.csv`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();

      toast.success(`${filename} downloaded successfully`);
    } catch (error) {
      toast.error(`Failed to download file : ${error}`);
    }
  };

  return exportRelatedEvidenceRequests;
};
