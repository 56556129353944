import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogContent, IconButton, Box } from "@mui/material";
import { Launch } from "@mui/icons-material";
import { EvidenceFile } from "../../types/evidencefiles";
import EvidenceFileViewer from "./viewFile";
import { GridCloseIcon } from "@mui/x-data-grid";

export const EvidenceFileButton = ({
  file,
  evidenceRequestId,
  iconVersion = false,
  smallIcon = false,
  pageNumber = 1,
  inChat = false,
}: {
  file: EvidenceFile;
  evidenceRequestId?: string;
  iconVersion?: boolean;
  smallIcon?: boolean;
  pageNumber?: number;
  inChat?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState<string>("");
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    if (evidenceRequestId) {
      setId(evidenceRequestId);
    }
  }, [evidenceRequestId]);

  return (
    <>
      {iconVersion ? (
        <IconButton
          onClick={handleOpen}
          size="small"
          title="Open"
          color="primary"
        >
          <Launch sx={{ fontSize: smallIcon ? "1rem" : "1.5rem" }} />
        </IconButton>
      ) : (
        <Button variant="contained" onClick={handleOpen} sx={{ mt: 2 }}>
          Open File
        </Button>
      )}
      <Dialog open={isOpen} onClose={handleClose} fullScreen>
        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
            <IconButton
              sx={{ position: "absolute", zIndex: 1000, top: 0, right: 0 }}
              onClick={handleClose}
              size="medium"
            >
              <GridCloseIcon />
            </IconButton>
          </Box>
          <Box>
            {id && (
              <EvidenceFileViewer
                pageNumber={pageNumber}
                evidenceFile={file}
                evidenceRequestId={id}
              />
            )}
            {!id && (
              <EvidenceFileViewer pageNumber={pageNumber} evidenceFile={file} />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EvidenceFileButton;
