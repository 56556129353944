import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  apiClient,
  getCookie,
  sessionExpiry,
  setCookie,
} from "../../api/apiClient";
import { User } from "../../types/users";

export const fetchCurrentUser = async (): Promise<User | null | undefined> => {
  if (!getCookie("user_data")) {
    return;
  }

  const storedUserData = getCookie("user_data");
  if (storedUserData) {
    return JSON.parse(storedUserData) as User;
  }

  try {
    const response = await apiClient.get("/api/users/me/", {});
    const userData: User = response.data;
    setCookie("user_data", JSON.stringify(userData));
    return userData;
  } catch (error) {
    //
    return null;
  }
};

const FinishLogin: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const tokenExchanged = useRef(false);

  useEffect(() => {
    if (code && !tokenExchanged.current) {
      exchangeCodeForTokens(code);
      tokenExchanged.current = true;
    }
  }, [code, navigate]);

  const exchangeCodeForTokens = async (code: string) => {
    try {
      const response = await apiClient.post("/api/exchange-token/", {
        code,
      });

      const { access_token, refresh_token, user, csrf_token } = response.data;

      setCookie("csrf_token", csrf_token);
      setCookie("user_data", JSON.stringify(user));
      setCookie("session_expiry", sessionExpiry());

      navigate("/");
    } catch (error) {
      console.error("Error during token exchange:", error);
      navigate("/login");
    }
  };

  fetchCurrentUser();

  return <div>Logging in...</div>;
};

export default FinishLogin;
