import React from "react";
import { Paper, Typography, Box, Grid, Button } from "@mui/material";
import { EvidenceRequest } from "../../types/evidencerequests";
import FileSearchPage from "./searchFiles";
import FileList from "./fileList";
import EvidenceFilesTable from "./fileList";
import { Link } from "react-router-dom";

interface EvidenceFileManagerProps {
  evidenceRequest: EvidenceRequest;
}

const EvidenceFileManager = ({ evidenceRequest }: EvidenceFileManagerProps) => {
  return (
    <Paper sx={{ padding: 1 }}>
      <Typography variant="h5" sx={{ p: 4, pb: 0 }}>
        Evidence Files
      </Typography>

      <Grid>
        <EvidenceFilesTable evidenceRequest={evidenceRequest} />

        {evidenceRequest && (
          <Box sx={{ p: 2, textAlign: "right" }}>
            <Button variant="contained">
              <Link
                to={`/file-search/?organization=${
                  evidenceRequest.organization?.id
                }&evidenceRequests=${
                  evidenceRequest.id
                }&engagements=${evidenceRequest.engagements
                  ?.map((engagement) => engagement.id)
                  .join(",")}`}
              >
                <Typography sx={{ color: "white" }}>Browse Files</Typography>
              </Link>
            </Button>
          </Box>
        )}
      </Grid>
    </Paper>
  );
};

export default EvidenceFileManager;
