import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { redirectTo } from "./navigation";
import Cookies from "js-cookie";
interface TokenResponse {
  access_token: string;
  refresh_token: string;
}

declare module "axios" {
  export interface AxiosRequestConfig {
    _retry?: boolean;
  }
}

export const sessionExpiry = () => {
  const now = new Date();
  const accessTokenExpiry = new Date(now.getTime() + 1 * 60 * 59 * 1000); // 59 minutes
  return accessTokenExpiry.toISOString();
};

export const setCookie = (cookie_name: string, cookie_value: string) => {
  Cookies.set(cookie_name, cookie_value, {
    secure: true,
    sameSite: "strict",
  });
};

export const setCookieHttpOnly = (
  cookie_name: string,
  cookie_value: string
) => {
  Cookies.set(cookie_name, cookie_value, {
    secure: true,
    sameSite: "strict",
    httpOnly: true,
  });
};

export const clearCookie = (cookie_name: string) => {
  Cookies.remove(cookie_name);
};

export const getCookie = (cookie_name: string) => {
  return Cookies.get(cookie_name);
};

export const baseURL =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? process.env.REACT_APP_API_BASE_URL
    : `https://${process.env.REACT_APP_API_BASE_URL}`;

export const apiClient: AxiosInstance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

apiClient.interceptors.request.use((config) => {
  const csrfToken = getCookie("csrf_token");
  if (csrfToken) {
    config.headers["X-CSRFToken"] = csrfToken;
  }
  return config;
});

let isRefreshing = false;
let refreshPromise: Promise<string> | null = null;
let failedQueue: Array<{
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void;
}> = [];

const refreshAccessToken = async (): Promise<string> => {
  try {
    const csrfToken = getCookie("csrf_token");
    const tokenResponse = await axios.post<TokenResponse>(
      `${baseURL}/api/access-token/`,
      {},
      {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      }
    );
    const { access_token } = tokenResponse.data;
    setCookie("access_token", access_token);
    setCookie("session_expiry", sessionExpiry());
    return access_token;
  } catch (error) {
    return Promise.reject(error);
  }
};

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

apiClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig & {
      _retry?: boolean;
    };

    if (error.response?.status !== 401 && error.response?.status !== 403) {
      return Promise.reject(error);
    }

    if (originalRequest._retry) {
      window.location.href = "/logout";
      return Promise.reject(error);
    }

    if (!isRefreshing) {
      isRefreshing = true;
      refreshPromise = refreshAccessToken();

      try {
        const newToken = await refreshPromise;
        processQueue(null, newToken);
        if (originalRequest.headers) {
          originalRequest.headers["Authorization"] = "Bearer " + newToken;
        }
        originalRequest._retry = true;
        return apiClient(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        window.location.href = "/logout";
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
        refreshPromise = null;
      }
    } else {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          if (originalRequest.headers && typeof token === "string") {
            originalRequest.headers["Authorization"] = "Bearer " + token;
          }
          return apiClient(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
  }
);

apiClient.interceptors.request.use((config) => {
  const csrfToken = getCookie("csrf_token");
  if (csrfToken) {
    config.headers["X-CSRFToken"] = csrfToken;
  }
  return config;
});
