import React from "react";
import { createBrowserRouter } from "react-router-dom";
import HomePageRouteWrapper from "./pages/home/index";
import Error from "./pages/error";
import Login from "./pages/login";
import RequireAuth from "./components/auth/RequireAuth";
import FinishLogin from "./pages/finish-login";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Logout } from "./components/auth/Logout";

import EngagementPageRouteWrapper from "./pages/engagements";
import EvidenceRequestsPageRouteWrapper from "./pages/evidencerequests";

import ControlPageRouteWrapper from "./pages/controls";
import ControlSetPageRouteWrapper from "./pages/controlsets";
import ComplianceStandardPageRouteWrapper from "./pages/compliancestandards";
import UserPageRouteWrapper from "./pages/users";
import ScopePageRouteWrapper from "./pages/scopes";
import EditControlSetPageRouteWrapper from "./components/controlsets/edit";
import EditEngagementPageRouteWrapper from "./components/engagements/edit";
import EditEvidenceRequestPageRouteWrapper from "./pages/evidencerequest/index";
import EditControlPageRouteWrapper from "./components/controls/edit";
import TermsAndConditions from "./pages/termsandconditions";
import TermsAndConditionsPopup from "./pages/termsandconditions";
import FileSearchPageRouteWrapper from "./pages/search-files";

const queryClient = new QueryClient();

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <HomePageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },
  {
    path: "/controls",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <ControlPageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },
  {
    path: "/file-search",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <FileSearchPageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },
  {
    path: "/control-sets",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <ControlSetPageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },
  {
    path: "/compliance-standards",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <ComplianceStandardPageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },
  {
    path: "/users",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <UserPageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },
  {
    path: "/engagements",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <EngagementPageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },
  {
    path: "/scopes",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <ScopePageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },

  {
    path: "/evidencerequests",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <EvidenceRequestsPageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },

  {
    path: "/evidence-request/:id",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <EditEvidenceRequestPageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },

  {
    path: "/engagement/:id",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <EditEngagementPageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },

  {
    path: "/controlset/:id",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <EditControlSetPageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },

  {
    path: "/control/:id",
    element: (
      <RequireAuth>
        <QueryClientProvider client={queryClient}>
          <EditControlPageRouteWrapper />
        </QueryClientProvider>
      </RequireAuth>
    ),
    errorElement: <Error />,
  },

  {
    path: "/login",
    element: <Login />,
    errorElement: <Error />,
  },
  {
    path: "/logout",
    element: <Logout />,
    errorElement: <Error />,
  },

  {
    path: "/finish-login",
    element: <FinishLogin />,
    errorElement: <Error />,
  },
  {
    path: "/termsandconditions",
    element: <TermsAndConditionsPopup />,
    errorElement: <Error />,
  },
]);
