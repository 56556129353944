import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  Tabs,
  Tab,
  Card,
  CardContent,
} from "@mui/material";
import Layout from "../../layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetEvidenceRequest,
  useSubmitForReviewEvidenceRequest,
} from "../../hooks/evidencerequests";
import EvidenceRequestChatInterface from "../../components/chat";
import { EvidenceRequest } from "../../types/evidencerequests";
import VerifierSelector from "./components/verifier";
import UserSelector from "./components/users";
import EngagementTable from "../../components/tables/engagements";
import { TabPanel, a11yProps } from "../../helpers/functions";
import ControlTable from "../../components/tables/controls";
import { getUserInfo } from "../../helpers/user";
import RelatedUserTable from "../../components/tables/users/related";
import EvidenceFileManager from "./fileManager";

const UserEditEvidenceRequestPage = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) {
    return <div>No ID provided</div>;
  }
  const [editedEvidenceRequest, setEditedEvidenceRequest] =
    useState<EvidenceRequest>();
  const navigator = useNavigate();
  const userInfo = getUserInfo();

  const isOrgAdmin = userInfo.role === "org_admin";

  const {
    data: evidenceRequestData,
    isLoading: evidenceRequestLoading,
    error: evidenceRequestError,
    refetch,
  } = useGetEvidenceRequest({ id });

  const submitEvidenceRequestForReview = useSubmitForReviewEvidenceRequest(id);

  const submitForReview = async () => {
    let note = "";
    const alertnote = window.prompt(
      "(Optional) Any notes to include with the submission?"
    );
    if (alertnote != null) {
      note = alertnote;
    }

    const confirmSubmit = window.confirm(
      "Are you sure you want to submit the Evidence Request for review?"
    );
    if (!editedEvidenceRequest) return;
    if (!confirmSubmit) return;

    const result = await submitEvidenceRequestForReview(note);
    if (result) {
      refetch();
    }
  };

  useEffect(() => {
    if (evidenceRequestData) {
      if (evidenceRequestData.evidencerequests[0]) {
        setEditedEvidenceRequest(evidenceRequestData.evidencerequests[0]);
      } else {
        window.alert("Evidence request not found!");
        navigator("/evidencerequests");
      }
    }
  }, [evidenceRequestData, evidenceRequestLoading]);

  const evidenceRequestInfoCard = (
    <>
      <Box
        sx={{
          m: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ textAlign: "left" }}>
          <Typography variant="h4" sx={{ mb: 0 }}>
            {editedEvidenceRequest?.title}
          </Typography>

          <Typography sx={{ mb: 2 }}>
            {editedEvidenceRequest?.status}
          </Typography>

          <Box sx={{ display: "flex", gap: 2 }}>
            {(editedEvidenceRequest?.status == "In Progress" ||
              editedEvidenceRequest?.status == "Followup Required" ||
              editedEvidenceRequest?.status == "Overdue") && (
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none" }}
                onClick={() => submitForReview()}
              >
                Submit For Review
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      {editedEvidenceRequest && (
        <>
          <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}></Box>

          {editedEvidenceRequest.description && (
            <Box>
              <Card sx={{ width: "100%", mb: 2 }}>
                <CardContent>
                  <Typography
                    component="div"
                    variant="body1"
                    sx={{
                      lineHeight: "1em",
                      padding: 2,
                      border: 2,
                      borderColor: "divider",
                      borderRadius: 3,
                      overflow: "wrap",
                    }}
                  >
                    <ReactMarkdown>
                      {editedEvidenceRequest.description}
                    </ReactMarkdown>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          )}
        </>
      )}
    </>
  );

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabId: number) => {
    setActiveTab(tabId);
  };

  const evidenceRequestInfo = (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => handleTabClick(newValue)}
            aria-label="Switch tabs"
          >
            <Tab label="Controls" {...a11yProps(0)} />
            <Tab label="Engagements" {...a11yProps(1)} />
            {isOrgAdmin && <Tab label="Users" {...a11yProps(2)} />}
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              Controls
            </Typography>

            {editedEvidenceRequest && (
              <>
                <ControlTable
                  parentObjectUrlKey="evidencerequests"
                  loadPartial={true}
                  parentObject={editedEvidenceRequest}
                  relatedChildFieldName="controls"
                />
              </>
            )}
          </Box>
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              Engagements
            </Typography>

            {editedEvidenceRequest && (
              <>
                <EngagementTable
                  parentObjectUrlKey="evidencerequests"
                  loadPartial={true}
                  parentObject={editedEvidenceRequest}
                  relatedChildFieldName="engagements"
                />
              </>
            )}
          </Box>
        </TabPanel>
        {isOrgAdmin && (
          <TabPanel value={activeTab} index={2}>
            <Box sx={{ padding: 2 }}>
              <Typography variant="h6" component="div">
                Users
              </Typography>

              {editedEvidenceRequest && (
                <>
                  <RelatedUserTable
                    parentObjectUrlKey="evidencerequests"
                    loadPartial={false}
                    parentObject={editedEvidenceRequest}
                    relatedChildFieldName="users"
                  />
                </>
              )}
            </Box>
          </TabPanel>
        )}
      </Box>
    </>
  );
  return (
    <Layout
      pageTitle="Evidence Request"
      parentUrl="/evidencerequests"
      parentPageName="All Evidence Requests"
      engagements={editedEvidenceRequest?.engagements}
    >
      <Box sx={{ p: 3, color: "black" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              margin: 2,
              borderRadius: 3,
            }}
          >
            {evidenceRequestInfoCard}
          </Box>

          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              color: "black",
              border: 2,
              borderColor: "divider",
              margin: 2,
              borderRadius: 4,
            }}
          >
            <Grid container spacing={2} padding={2}>
              <Grid item sm={12} md={5}>
                <Paper sx={{ padding: 1 }}>
                  <Typography variant="h5" sx={{ p: 4, pb: 0 }}>
                    Conversation
                  </Typography>{" "}
                  {editedEvidenceRequest && (
                    <EvidenceRequestChatInterface
                      evidenceRequest={editedEvidenceRequest}
                      refetch={refetch}
                      conversationId={
                        evidenceRequestData?.evidencerequests[0]
                          ?.message_history ?? ""
                      }
                    />
                  )}
                </Paper>
              </Grid>
              <Grid item sm={12} md={7}>
                {editedEvidenceRequest && (
                  <EvidenceFileManager
                    evidenceRequest={editedEvidenceRequest}
                  />
                )}
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              margin: 2,
              marginTop: 0,
              borderRadius: 3,
            }}
          >
            <Box sx={{ pt: 4, display: "flex", gap: 1 }}>
              {editedEvidenceRequest &&
                editedEvidenceRequest.users.length != 0 &&
                setEditedEvidenceRequest && (
                  <Box sx={{ flex: 1 }}>
                    <UserSelector
                      editMode={false}
                      editedEvidenceRequest={editedEvidenceRequest}
                      setEditedEvidenceRequest={setEditedEvidenceRequest}
                    />
                  </Box>
                )}
              {editedEvidenceRequest &&
                editedEvidenceRequest.verifiers.length != 0 &&
                setEditedEvidenceRequest && (
                  <Box sx={{ flex: 1 }}>
                    <VerifierSelector
                      editMode={false}
                      editedEvidenceRequest={editedEvidenceRequest}
                      setEditedEvidenceRequest={setEditedEvidenceRequest}
                    />
                  </Box>
                )}
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              color: "black",
              border: 2,
              borderColor: "divider",
              margin: 2,
              borderRadius: 3,
            }}
          >
            {evidenceRequestInfo}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default UserEditEvidenceRequestPage;
