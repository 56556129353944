import React from "react";
import { Navigate } from "react-router-dom";

import { getUserInfo } from "../../helpers/user";
import Error from "../error";
import FileSearchPage from "../evidencerequest/searchFiles";

const FileSearchPageRouteWrapper = () => {
  const userInfo = getUserInfo();

  if (userInfo.role === "admin" || userInfo.role === "auditor") {
    return <FileSearchPage />;
  } else if (userInfo.role === "user" || userInfo.role === "org_admin") {
    return <Error />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default FileSearchPageRouteWrapper;
