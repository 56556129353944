import React, { useEffect, useState } from "react";
import { Card, TextField, IconButton, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete } from "@mui/material";
import { EvidenceRequest } from "../../../types/evidencerequests";
import { useSearchUsers } from "../../../hooks/users";
import { User } from "../../../types/users";
import { UserAvatar } from "./avatar";

interface VerifierSelectorProps {
  editedEvidenceRequest: EvidenceRequest;
  setEditedEvidenceRequest: React.Dispatch<
    React.SetStateAction<EvidenceRequest | undefined>
  >;
  editMode?: boolean;
}

const VerifierSelector: React.FC<VerifierSelectorProps> = ({
  editedEvidenceRequest,
  setEditedEvidenceRequest,
  editMode = true,
}) => {
  const [inputValue, setInputValue] = useState("");

  const { data: userQueryResult, refetch } = useSearchUsers({
    q: inputValue,
    organization: "",
    intent: "verifiers",
  });
  const users = userQueryResult?.[0]?.users || [];

  useEffect(() => {
    if (inputValue.length >= 3) {
      refetch();
    }
  }, [inputValue, refetch]);

  const handleRemoveVerifier = (userId: string) => {
    const filteredVerifiers = editedEvidenceRequest.verifiers.filter(
      (user) => user.id !== userId
    );
    setEditedEvidenceRequest({
      ...editedEvidenceRequest,
      verifiers: filteredVerifiers,
    });
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          padding: 2,
          boxSizing: "border-box",
          height: editMode ? "24vh" : "18vh",
        }}
      >
        <Typography sx={{ m: 2 }} variant="h5">
          Verifiers
        </Typography>

        {editMode && (
          <Autocomplete
            multiple
            id="verifier-search"
            options={users}
            renderTags={() => {
              return "";
            }}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name} - ${option.email}`
            }
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={editedEvidenceRequest.verifiers}
            onChange={(event, newValue: User[]) => {
              setEditedEvidenceRequest({
                ...editedEvidenceRequest,
                verifiers: newValue,
              });
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search and select verifiers"
                placeholder="Type to search..."
              />
            )}
          />
        )}
        <Box sx={{ marginTop: 2, display: "flex", flexWrap: "wrap", gap: 2 }}>
          {editedEvidenceRequest.verifiers.map((user: User) => (
            <Box
              key={user.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <UserAvatar user={user} />

              {editMode && (
                <IconButton
                  onClick={() => handleRemoveVerifier(user.id)}
                  size="small"
                >
                  <CloseIcon sx={{ color: "black" }} />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
      </Card>
    </>
  );
};

export default VerifierSelector;
