import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Stack,
  Alert,
  IconButton,
  TextField,
  InputAdornment,
  useTheme,
  ListItem,
  ListItemText,
  Drawer,
  LinearProgress,
  Paper,
  Fade,
} from "@mui/material";
import { EvidenceFile } from "../../types/evidencefiles";
import { pdfjs, Document, Page } from "react-pdf";
import {
  AddComment,
  ArrowDownwardRounded,
  ArrowUpwardRounded,
  CenterFocusStrong,
  Clear,
  Search,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { apiClient } from "../../api/apiClient";
import EvidenceRequestChatInterface from "../../components/chat";
import { useGetEvidenceRequest } from "../../hooks/evidencerequests";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const DRAWER_WIDTH = 120;

interface OutlineItem {
  title: string;
  pageNumber: number;
  items?: OutlineItem[];
  level: number;
}

interface TextMatch {
  matchIndex: number;
  pageIndex: number;
  str: string;
  width: number;
  height: number;
  top: number;
  left: number;
}

interface SearchResponse {
  matches: TextMatch[];
  total: number;
}
interface TextHighlightProps {
  match: TextMatch;
  scale: number;
  isActive: boolean;
}

const RenderPDF = ({
  evidenceFile,
  fileUrlAnnotated,
  downloadLoadingAnnotated,
  downloadErrorAnnotated,
  renderDocumentInfo,
  pageNumber = 1,
  evidenceRequestId,
}: {
  evidenceFile: EvidenceFile;
  fileUrlAnnotated: string;
  downloadLoadingAnnotated: boolean;
  downloadErrorAnnotated: boolean;
  renderDocumentInfo: () => JSX.Element;
  pageNumber: number;
  evidenceRequestId?: string;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [scale, setScale] = useState<number>(1.0);
  const [initialScale, setInitialScale] = useState<number | null>(null);
  const [searchText, setSearchText] = useState("");

  const [currentMatchIndex, setCurrentMatchIndex] = useState(-1);
  const [matches, setMatches] = useState<TextMatch[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [loadedThumbnails, setLoadedThumbnails] = useState<Set<number>>(
    new Set()
  );
  const [searching, setSearching] = useState(false);
  const lastSearchedText = useRef("");

  const theme = useTheme();
  const pageRef = useRef<HTMLDivElement>(null);

  const onThumbnailRenderSuccess = (pageNumber: number) => {
    setLoadedThumbnails((prev) => new Set(prev).add(pageNumber));
  };

  const {
    data: evidenceRequestData,
    isLoading: evidenceRequestLoading,
    error: evidenceRequestError,
    refetch,
  } = useGetEvidenceRequest({ id: evidenceRequestId || "" });

  const fetchedEvidenceRequest =
    evidenceRequestData && evidenceRequestData.evidencerequests[0];

  const renderThumbnails = () => (
    <Box
      sx={{
        position: "fixed",
        marginTop: "16px",
        height: "650px",
        overflowY: "auto",
        overflowX: "hidden",
        p: 1,
        pt: 0,
      }}
    >
      <Stack spacing={1}>
        <Document file={fileUrlAnnotated}>
          {Array.from(new Array(numPages), (_, index) => (
            <Box
              key={`thumb_${index + 1}`}
              onClick={() => scrollToPage(index + 1)}
              sx={{
                cursor: "pointer",
                border: "1px solid",
                height: "120px",
                width: "90px",

                borderColor: "divider",
                borderRadius: 1,
                overflow: "hidden",
                "&:hover": {
                  boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                },
              }}
            >
              <Page
                pageNumber={index + 1}
                height={100}
                onRenderSuccess={() => onThumbnailRenderSuccess(index + 1)}
                loading={
                  <Box
                    sx={{
                      height: "120px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={30} />
                  </Box>
                }
              />

              <Typography
                variant="caption"
                sx={{ p: 0.5, display: "block", textAlign: "center" }}
              >
                Page {index + 1}
              </Typography>
            </Box>
          ))}
        </Document>
      </Stack>
    </Box>
  );

  const scrollToMatch = (match: TextMatch) => {
    const container = document.querySelector(".pdf-content-container");
    if (!container) return;

    const pageElement = container.querySelector(
      `[data-page-number="${match.pageIndex + 1}"]`
    );

    if (pageElement) {
      pageElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigateSearch = (direction: "next" | "prev") => {
    if (matches.length === 0) return;

    const newIndex =
      direction === "next"
        ? (currentMatchIndex + 1) % matches.length
        : (currentMatchIndex - 1 + matches.length) % matches.length;

    setCurrentMatchIndex(newIndex);
    const match = matches[newIndex];
    scrollToMatch(match);
  };

  const handleSearch = useCallback(async () => {
    if (!searchText) {
      setMatches([]);
      return;
    }

    try {
      setSearching(true);

      const response = await apiClient.post<SearchResponse>(
        `/api/evidencefiles/text-search/?id=${evidenceFile.id}`,
        {
          fileUrl: fileUrlAnnotated,
          searchText: searchText,
        },
        {
          headers: {
            "X-API-Key": "a_secure_api_key",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      setMatches(response.data.matches);

      if (matches.length > 0) {
        setCurrentMatchIndex(0);
        scrollToMatch(matches[0]);
      } else {
        setCurrentMatchIndex(-1);
      }

      lastSearchedText.current = searchText;
      return response.data;
    } catch (error) {
      console.error("Search failed:", error);
    } finally {
      setSearching(false);
    }
  }, [searchText, navigateSearch]);

  const [loadingProgress, setLoadingProgress] = useState(0);

  const onDocumentLoadProgress = ({
    loaded,
    total,
  }: {
    loaded: number;
    total: number;
  }) => {
    const progress = (loaded / total) * 100;
    setLoadingProgress(Math.min(progress, 99));
  };

  const handlePageClick = (pageNumber: number) => {
    //
  };

  const renderPage = (pageNumber: number) => {
    const pageMatches = matches.filter(
      (match) => match.pageIndex === pageNumber - 1
    );

    return (
      <Box
        key={`page_${pageNumber}`}
        sx={{
          mb: 2,
          boxShadow: "0 0 10px rgba(0,0,0,0.1)",
          border: "1px solid rgba(0,0,0,0.12)",
          borderRadius: 1,
          overflow: "hidden",
          width: "fit-content",
          position: "relative",
          pointer: "cursor",
        }}
      >
        <Page
          pageNumber={pageNumber}
          scale={scale}
          data-page-number={pageNumber}
        />

        <IconButton
          size="large"
          onClick={(e) => {
            handlePageClick(pageNumber);
          }}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 9999,
            backgroundColor: "rgba(255,255,255,0.9)",
          }}
        >
          <AddComment />
        </IconButton>

        <Typography
          variant="body2"
          sx={{
            position: "absolute",
            bottom: 8,
            right: 8,
            backgroundColor: "rgba(255,255,255,0.9)",
            padding: "4px 8px",
            borderRadius: 1,
            boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
            fontWeight: "medium",
            userSelect: "none",
          }}
        >
          {pageNumber}
        </Typography>

        {pageMatches.map((match, idx) => (
          <TextHighlight
            key={`highlight-${pageNumber}-${idx}`}
            match={match}
            scale={scale}
            isActive={currentMatchIndex === match.matchIndex}
          />
        ))}
      </Box>
    );
  };

  const TextHighlight: React.FC<TextHighlightProps> = ({
    match,
    scale,
    isActive,
  }) => (
    <Box
      sx={{
        position: "absolute",
        width: match.width * scale,
        height: match.height * scale,
        top: match.top * scale,
        left: match.left * scale,
        backgroundColor: isActive
          ? "rgba(255, 215, 0, 0.6)"
          : "rgba(255, 255, 0, 0.2)",
        borderRadius: "3px",
        padding: "2px",
        pointerEvents: "none",
        transition: "background-color 0.2s ease",
      }}
    />
  );

  const [isEnterPressed, setIsEnterPressed] = useState(false);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !isEnterPressed) {
      setIsEnterPressed(true);

      if (searchText !== lastSearchedText.current) {
        handleSearch();
      } else if (matches.length > 0) {
        e.preventDefault();
        if (e.shiftKey) {
          navigateSearch("prev");
        } else {
          navigateSearch("next");
        }
      }
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setIsEnterPressed(false);
    }
  };

  const scrollToPage = (pageNumber: number) => {
    const container = document.querySelector(".pdf-content-container");

    if (!container) {
      return;
    }
    const pageElement = container.querySelector(
      `[data-page-number="${pageNumber}"]`
    );
    if (pageElement) {
      if (container) {
        pageElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleZoomIn = () => setScale((prev) => Math.min(prev + 0.3, 5));
  const handleResetZoom = () => setScale(initialScale || 1);
  const handleZoomOut = () => setScale((prev) => Math.max(prev - 0.3, 0.1));

  const onDocumentLoadSuccess = async (pdfDoc: any) => {
    setNumPages(pdfDoc.numPages);

    try {
      const page = await pdfDoc.getPage(1);
      const width = page.view[2];

      if (!initialScale) {
        const newScale = calculateScale(width);
        setInitialScale(newScale);
        setScale(newScale);
      }

      setTimeout(() => {
        setIsLoading(false);
        scrollToPage(pageNumber - 1);
      }, 2000);
    } catch (error) {
      console.error("Error getting page dimensions:", error);
      setIsLoading(false);
    }
  };

  const renderOutlineItem = (item: OutlineItem) => {
    const handleClick = () => {
      scrollToPage(item.pageNumber);
    };

    return (
      <Box key={`${item.title}-${item.pageNumber}`}>
        <ListItem
          button
          onClick={handleClick}
          sx={{
            pl: item.level * 2,
            borderLeft: item.level === 0 ? "none" : "1px solid",
            borderColor: "divider",
            ml: item.level === 0 ? 0 : 2,
          }}
        >
          <ListItemText
            primary={item.title}
            secondary={`Page ${item.pageNumber}`}
            primaryTypographyProps={{
              fontSize: theme.typography.body2.fontSize,
              fontWeight: item.level === 0 ? "medium" : "normal",
            }}
            secondaryTypographyProps={{
              fontSize: theme.typography.caption.fontSize,
            }}
          />
        </ListItem>
        {item.items?.map((subItem) => renderOutlineItem(subItem))}
      </Box>
    );
  };

  const renderTableOfContents = () => (
    <Drawer
      variant="permanent"
      sx={{
        width: DRAWER_WIDTH,
        zIndex: 0,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
          position: "sticky",
          top: 0,
        },
      }}
    ></Drawer>
  );

  const calculateScale = (width: number) => {
    if (!containerRef.current || !width) return 1;
    const containerWidth = containerRef.current.offsetWidth - 48;
    return (containerWidth / width) * 0.8;
  };

  const renderControls = () => (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 999,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          backgroundColor: "background.paper",
          p: 1,
          pt: 0,
          mt: -2,
          borderRadius: 1,
          border: "2px solid lightgray",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
          <IconButton title="Zoom out" onClick={handleZoomOut} size="small">
            <ZoomOut />
          </IconButton>
          <IconButton title="Reset zoom" onClick={handleResetZoom} size="small">
            <CenterFocusStrong />
          </IconButton>
          <IconButton title="Zoom in" onClick={handleZoomIn} size="small">
            <ZoomIn />
          </IconButton>

          <IconButton title="Scale" size="small">
            {(scale * 100).toFixed(0)}%
          </IconButton>

          <Typography
            variant="h6"
            sx={{
              pl: 2,
              width: "400px",
              overflow: "scroll",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {evidenceFile.original_name}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1, p: 2 }}>
          <TextField
            size="small"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            placeholder="Search..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchText && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSearchText("");
                        setMatches([]);
                        setSearching(false);
                      }}
                    >
                      <Clear />
                    </IconButton>
                  )}
                  {searching && (
                    <IconButton size="small">
                      <CircularProgress size={20} />
                    </IconButton>
                  )}
                  <IconButton size="small" onClick={handleSearch}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ width: "120px", textAlign: "right", p: 0 }}>
            <IconButton size="small" onClick={() => navigateSearch("prev")}>
              {matches.length > 0 && <ArrowUpwardRounded />}
            </IconButton>
            <IconButton size="small" onClick={() => navigateSearch("next")}>
              {matches.length > 0 && <ArrowDownwardRounded />}
            </IconButton>
            {matches.length > 0 && (
              <Typography variant="body2" sx={{ px: 2 }}>
                {currentMatchIndex + 1} of {matches.length}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );

  if (!evidenceFile) {
    return <Typography>No file selected</Typography>;
  }

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "sticky",
      }}
    >
      {renderControls()}
      {downloadLoadingAnnotated ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        ></Box>
      ) : downloadErrorAnnotated ? (
        <Alert severity="error" sx={{ m: 2 }}>
          Error loading file
        </Alert>
      ) : fileUrlAnnotated ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            position: "sticky",
            top: 0,
            overflow: "scroll",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              zIndex: 999,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
              backgroundColor: "background.paper",
              p: 1.5,
              m: 0,
              width: "100%",
              borderRadius: 2,
              border: "2px solid lightgray",
            }}
          >
            {renderDocumentInfo()}
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            {evidenceRequestId && (
              <Box
                sx={{
                  width: "33.333%",
                  height: "80vh",

                  borderColor: "grey.200",
                  padding: 2,
                }}
              >
                <Box sx={{ position: "fixed", width: "33%" }} className="panel">
                  {fetchedEvidenceRequest && (
                    <EvidenceRequestChatInterface
                      evidenceRequest={fetchedEvidenceRequest}
                      refetch={refetch}
                      conversationId={fetchedEvidenceRequest.message_history}
                    />
                  )}
                </Box>
              </Box>
            )}
            <Box
              sx={{
                width: evidenceRequestId ? "66.666%" : "90%",
                overflow: isLoading ? "hidden" : "scroll",
                borderLeft: "1px solid lightgray",
              }}
            >
              {isLoading && (
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 2,
                    border: "2px solid lightgray",
                    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80vh",
                    overflow: "hidden",

                    m: 2,
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={loadingProgress}
                    size={60}
                  />
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{ mt: 2 }}
                  >
                    Loading PDF... {Math.round(loadingProgress)}%
                  </Typography>
                  <Box sx={{ width: "100%", mt: 2, maxWidth: 400 }}>
                    <LinearProgress
                      variant="determinate"
                      value={loadingProgress}
                      sx={{ height: 8, borderRadius: 1 }}
                    />
                  </Box>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 1 }}
                  >
                    {loadingProgress < 100
                      ? "Downloading PDF file..."
                      : "Preparing document..."}
                  </Typography>
                </Paper>
              )}
              <Box
                className="pdf-content-container"
                ref={containerRef}
                sx={{
                  margin: "16px",
                  marginBottom: "100px",
                  borderRadius: 2,
                  border: "2px solid lightgray",
                  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                  overflow: "scroll",
                  minHeight: "80vh",
                  visibility: isLoading ? "hidden" : "visible",
                }}
              >
                <Document
                  file={fileUrlAnnotated}
                  renderMode="canvas"
                  onLoadProgress={onDocumentLoadProgress}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (_, index) =>
                    renderPage(index + 1)
                  )}
                </Document>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Typography sx={{ p: 2 }}>No annotated version available</Typography>
      )}
    </Box>
  );
};

export default RenderPDF;
