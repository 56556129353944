import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import { EvidenceFile } from "../../types/evidencefiles";
import { EvidenceRequest } from "../../types/evidencerequests";
import { useGetEvidenceFile } from "../../hooks/evidencefiles";
import { Box, Button, IconButton } from "@mui/material";
import FileUploader from "./fileUpload";
import { GridSaveAltIcon } from "@mui/x-data-grid";
import { useGetObjects } from "../../hooks/common";
import { Launch } from "@mui/icons-material";
import { EvidenceFileButton } from "./evidenceFileButton";

const EvidenceFilesTable = ({
  evidenceRequest,
}: {
  evidenceRequest: EvidenceRequest;
}) => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const showContent = false;

  const sortConfig = useMemo(() => {
    if (sorting.length === 0) return { field: undefined, order: undefined };
    return {
      field: sorting[0].id,
      order: sorting[0].desc ? "desc" : "asc",
    };
  }, [sorting]);

  const relatedParentField = "evidencerequests";
  const relatedChildField = "";
  const relatedParentId = evidenceRequest.id;
  const showRelatedOnly = true;

  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
    relatedParentField,
    relatedChildField,
    relatedParentId,
    showRelatedOnly,
  };

  const {
    data: fetchedEvidenceFiles = [],
    isError: error,
    isLoading: loading,
    isFetching: fetching,
  } = useGetObjects<
    EvidenceFile,
    [
      "evidencefiles",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
        relatedParentField: string | undefined;
        relatedChildField: string | undefined;
        relatedParentId: string | undefined;
        showRelatedOnly: boolean;
      }
    ]
  >(
    [
      "evidencefiles",
      {
        pagination,
        columnFilters,
        sorting,
        globalFilter,
        relatedParentField,
        relatedChildField,
        relatedParentId,
        showRelatedOnly,
      },
    ],
    "evidencefiles",
    getParams
  );

  useEffect(() => {
    const count = fetchedEvidenceFiles[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [fetchedEvidenceFiles]);

  const tableName = "evidence_files_table";

  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return tableState[tableName]?.columnVisibility || { id: false };
  });
  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const { fetchDownloadUrl } = useGetEvidenceFile();

  const downloadFile = async (evidenceFileId: string) => {
    const url = await fetchDownloadUrl(evidenceFileId);
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const columns = useMemo<MRT_ColumnDef<EvidenceFile>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },
      {
        accessorKey: "original_name",
        header: "File name",
        enableSorting: true,
        size: 400,
        Cell: ({ row }) => {
          return <>{row.original.original_name}</>;
        },
      },

      {
        accessorKey: "date_created",
        enableColumnFilter: false,
        header: "Uploaded",
        enableSorting: true,
        Cell: ({ row }) => {
          if (row.original.date_created) {
            const date = new Date(row.original.date_created);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }).format(date);
          }
        },
      },

      {
        accessorKey: "options",
        header: "Options",
        enableSorting: false,
        enableColumnFilter: false,

        Cell: ({ row }) => {
          return (
            <>
              <IconButton
                onClick={() => downloadFile(row.original.id)}
                size="small"
                title={`Download ${row.original.original_name}`}
                color="primary"
              >
                <GridSaveAltIcon />
              </IconButton>

              {evidenceRequest.id && (
                <EvidenceFileButton
                  evidenceRequestId={evidenceRequest.id}
                  file={row.original}
                  iconVersion={true}
                />
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    muiTablePaperProps: {
      elevation: 0,

      sx: {
        borderRadius: "0",
        pl: 2,
        pr: 2,
      },
    },

    data:
      fetchedEvidenceFiles && fetchedEvidenceFiles[0]
        ? fetchedEvidenceFiles[0].data
        : [],
    initialState: {
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableGlobalFilter: true,
    onColumnVisibilityChange: setColumnVisibility,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    renderBottomToolbarCustomActions: ({ table }) => (
      <>
        <FileUploader evidenceRequestId={evidenceRequest.id} />
      </>
    ),
    muiToolbarAlertBannerProps: error
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: rowCount,

    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      columnVisibility,
      isLoading: loading,
      showAlertBanner: error,
      showProgressBars: fetching,
    },
  });

  return (
    <>
      <MaterialReactTable table={table} />
    </>
  );
};

export default EvidenceFilesTable;
